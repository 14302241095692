import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/AIHeuristicCard/card/right.png';
const _withScopeId = n => (_pushScopeId("data-v-1f205be0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "game-map-center"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "cover-desc"
};
const _hoisted_5 = {
  class: "common-text"
};
const _hoisted_6 = {
  class: "common-text"
};
const _hoisted_7 = {
  class: "bottom-list"
};
const _hoisted_8 = ["src", "onClick"];
import { ref } from 'vue';
import study from '@/assets/images/AIHeuristicCard/card/study.jpg';
import Rectangle from '@/assets/images/AIHeuristicCard/card/Rectangle 2395.png';
import Scorecard from '@/assets/images/AIHeuristicCard/card/Scorecard.jpg';
import i18n from '@/language/index';
export default {
  __name: 'CenterContent',
  setup(__props) {
    const currentIndex = ref(0);
    const imgList = ref([{
      url: study,
      title: i18n.global.t('aiHeuristicCard.gameMap.imgOneTitle'),
      desc: i18n.global.t('aiHeuristicCard.gameMap.imgOneDesc')
    }, {
      url: Rectangle,
      title: i18n.global.t('aiHeuristicCard.gameMap.imgTwoTitle'),
      desc: i18n.global.t('aiHeuristicCard.gameMap.imgTwoDesc')
    }, {
      url: Scorecard,
      title: i18n.global.t('aiHeuristicCard.gameMap.imgThreeTitle'),
      desc: i18n.global.t('aiHeuristicCard.gameMap.imgThreeDesc')
    }]);
    function prev() {
      if (currentIndex.value === 0) {
        currentIndex.value = imgList.value.length - 1;
      } else {
        currentIndex.value -= 1;
      }
    }
    function next() {
      if (currentIndex.value === imgList.value.length - 1) {
        currentIndex.value = 0;
      } else {
        currentIndex.value += 1;
      }
    }
    function handleChange(index) {
      currentIndex.value = index;
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        class: "left",
        onClick: prev,
        src: _imports_0,
        alt: ""
      }), _createElementVNode("img", {
        src: imgList.value[currentIndex.value].url,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(imgList.value[currentIndex.value].title), 1), _createElementVNode("p", _hoisted_6, _toDisplayString(imgList.value[currentIndex.value].desc), 1)]), _createElementVNode("img", {
        class: "right",
        onClick: next,
        src: _imports_0,
        alt: ""
      }), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imgList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("img", {
          src: item.url,
          alt: "",
          class: _normalizeClass(currentIndex.value === index ? 'current-img small-img' : 'small-img'),
          onClick: $event => handleChange(index)
        }, null, 10, _hoisted_8)]);
      }), 128))])])]);
    };
  }
};